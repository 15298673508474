import React from 'react'

import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import SubHeader from '../components/sub-header'
import Legal from '../components/legal'
import Talk from '../components/lets-talk'
import Footer from '../components/footer'
import Cookies from '../components/cookies'

const Privacy = () => {
    return (
        <Store>
            <Head title="Privacy Policy" />
            <Navigation />
            <Header disable />
            <SubHeader title="Privacy Policy" />
            <Legal>
                <p>Host3 respects and takes your privacy very seriously. This Policy applies to all products and services. Please read this privacy policy before using the site or submitting any personal information. By using the site, you are accepting the practices described in this privacy policy, If you have any questions about this Policy, please feel free to contact us through our website.</p>

                <p>These practices may be changed, but any changes will be posted and changes will only apply to activities and information on a going forward, not retroactive basis. You are encouraged to review the privacy policy whenever you visit the site to make sure that you understand how any personal information you provide will be used.</p>

                <p>Note: the privacy practices set forth in this privacy policy are for this web site only. If you link to other web sites, please review the privacy policies posted at those sites.</p>

                <h4>Cookie/Tracking Technology</h4>
                <p>Host3 uses cookies to help provide the best possible customer experience for our existing and potential customers. Cookies are small text files that are temporarily stored on your computer when vesting websites with your browser. They are often necessary for the normal operation of the website. Cookies can be de-activated using your browser settings.</p>

                <p>Cookie and tracking technology are useful for gathering information such as browser type and operating system, tracking the number of visitors to the site, and understanding how visitors use the website.Cookies can also help customise the website for visitors.Personal information cannot be collected via cookies and other tracking technology, however, if you previously provided personally identifiable information, cookies may be tied to such information.</p>

                <h4>Commitment to Data Security</h4>
    <p>Your personally identifiable information is kept secure.Only authorised employees, agents and contractors(who have agreed to keep information secure and confidential) have access to this information.All emails and newsletters from this site allow you to opt out of further mailings.</p>

                <h4>Changes to the Privacy and Cookies policy</h4>

                    <p>Host3 reserves the right to revise its policies at any time without notice.</p>

            </Legal>
            <Talk />
            <Footer />
            <Cookies />
        </Store>
    )
}

export default Privacy
